import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import api from "../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  Table,
  Spinner,
  Badge,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import {
  formatDataToDate
} from '../../../helpers/mascarasParaCampos';

const MembersSearch = props => {

  const navigate = useNavigate();

  let NameModule = 'Pesquisar Membros';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);
  const [BranchesData, setBranchesData] = useState([]);
  const [StatesData, setStatesData] = useState([]);
  const [CitiesData, setCitiesData] = useState([]);
  const [UnitsData, setUnitsData] = useState([]);
  
  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);
  const [FormData, setFormData] = useState({});

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "branches": FormData.branches || '',
        "states": FormData.states || '',
        "units": FormData.units || '',         
        "cities": FormData.cities || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/users/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  function LoadBranches() {
    setBranchesData([]);
    api.get('/api/branches/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setBranchesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  const LoadStates = async => {
    setStatesData([]);
    setCitiesData([]);
    api.get('/api/states/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setStatesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadUnits() {
    setUnitsData([]);
    api.get('/api/units/active').then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setUnitsData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadCities(id) {
    setCitiesData([]);
    api.get('/api/cities/active/states/' + id).then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setCitiesData(data.data);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  useEffect(() => {
    LoadUnits();
    LoadBranches();
    LoadStates();
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="branches"
                        className="branches"
                        name="branches"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'branches': event.target.value });
                        }}
                        value={FormData.branches ? FormData.branches : ''}
                      >
                        <option value=""> Todos </option>
                        {BranchesData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Ramo de Nengócio
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="units"
                        className="units"
                        name="units"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'units': event.target.value });
                        }}
                        value={FormData.units ? FormData.units : ''}
                      >
                        <option value=""> Todos </option>
                        {UnitsData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Unidade
                      </Label>
                    </FormGroup>
                  </Col>                  
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="states"
                        className="states"
                        name="states"
                        type="select"
                        placeholder="Estado"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'states': event.target.value });
                          LoadCities(event.target.value);
                        }}
                        value={FormData.states ? FormData.states : ''}
                      >
                        <option value=""> Todos </option>
                        {StatesData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Estado
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        disabled={FormData.states ? false : true}
                        id="cities"
                        className="cities"
                        name="cities"
                        type="select"
                        placeholder="Cidade"
                        onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                        value={FormData.name ? FormData.name : ''}
                      >
                        <option value=""> Todos </option>
                        {CitiesData.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Cidade
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th></th>
                      <th></th>
                      <th> Membro </th>
                      <th> Estado </th>
                      <th> Cidade </th>
                      <th> Ramo </th>
                      <th> Unidade </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'middle' }}>
                              <Link
                                to={"/m/" + row.slug}
                                target="_blank"
                              >
                                <Button
                                  type="button"
                                  color="success"
                                  size="sm"
                                  style={{ fontSize: '13px' }}
                                >
                                  Ver página
                                </Button>
                              </Link>

                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <div style={{
                                position: 'relative',
                                margin: '0 auto',
                                height: '40px',
                                width: '40px',
                                backgroundImage: `url('https://api.empresariossa.com/storage/images/avatar/${row && row.avatar ? row.avatar : 'default.jpg'}')`,
                                backgroundSize: 'cover',
                                borderRadius: '50%'
                              }}>
                                <img alt="" className="sidebar-avatar" src={`https://api.empresariossa.com/storage/images/avatar/spc.png`} />
                              </div>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.name ? row.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.states ? row.states.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.cities ? row.cities.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.branches ? row.branches.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.units ? row.units.name : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    xs={4}
                  >
                    Página
                  </Label>
                  <Col xs={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersSearch;