/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import api from '../services/api';
import { showToastArray } from '../helpers/generals';

export default function LayoutHeader({
    show,
    isAdmin,
}) {

    const navigate = useNavigate();

    let UserID = localStorage.getItem('UserID');

    const [UserType, setUserType] = useState('');
    const [UserAvatar, setUserAvatar] = useState('');

    const [Loading, setLoading] = useState(true);
    const [MenuItems, setMenuItems] = useState([]);

    useEffect(() => {
        async function LoadData() {
            try {
                if (UserID) {
                    api.get('/api/users/show/' + UserID).then(response => {
                        return response.data;   
                    }).then(data => { 
                        if (data.results > 0) {

                            if (data.data && data.data.type == 'member' && (data.data.status < 6)) {
                                checkFinancial ("Seu usuário está inativo, por favor, faça entrevista com o administrador para ativar seu acesso.");
                            } 

                            if (data.data && data.data.type == 'member' && (data.data.status > 5 && data.data.status < 7) && data.data.situation_now && data.data.situation_now.length > 0) {
                                checkFinancial ("Você está com pendências financeiras, por favor, regularize sua situação para continuar acessando o sistema.");
                            } 

                            setUserType(data.data.type);
                            setUserAvatar(data.data.avatar);
                            setMenuItems(data.modules);
                            setLoading(false);
                        }
                    }).catch(error => {
                        // console.log(error);
                    });
                }
            } catch (error) {
                // console.log(error);
            }
        }
        LoadData();
    }, [UserID, UserAvatar]);

    function checkFinancial (message) {
        showToastArray("error", "Aviso!", message);       
        navigate('/login');
    }
    return (
        <React.Fragment>
            <div className="header-box">
                <Container fluid>
                    <Row className='no-padding'>
                        <Col sm="2" className={UserType === 'member' ? "member header-logo" : "header-logo"}>
                            <Link to="/dashboard">
                                <img src={Logo} />
                            </Link>
                        </Col>
                        {/* <Col sm="3">
                            <Input className="header-search" placeholder="Pequisar..." />
                        </Col> */}
                        <Col sm="10" className='no-padding-mobile'>

                            <nav className="no-padding">
                                <ul>
                                    <li>
                                        {UserType === 'member' ? (
                                            <Link to="/dashboard">
                                                <img className="logo-mobile-member" src={Logo} />
                                            </Link>
                                        ) : null}
                                    </li>
                                    {!Loading ? (
                                        MenuItems?.length > 0 ? (
                                            MenuItems.map((row, index) => (
                                                <li key={index}>
                                                    {row.vincs?.length === 0 ? (
                                                        <Link to={row.route}>
                                                            <i class={row.icon}></i> <span>{row.name}</span>
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            <Link to="">
                                                                <i class={row.icon}></i> <span>{row.name}</span>
                                                            </Link>
                                                            <ul>
                                                                {row.vincs.map((row2, index2) => (
                                                                    <li key={index2}>
                                                                        <Link to={row2.route}>
                                                                            {row2.name}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}
                                                </li>
                                            ))
                                        ) : null
                                    ) : (
                                        <>
                                            <li>
                                                <div style={{ width: '100%', padding: '8px 20px', textAlign: 'center' }}>
                                                    <Spinner></Spinner>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                    <li>
                                        <div class="header-avatar">                                         
                                            <img src={`https://api.empresariossa.com/storage/images/avatar/${UserAvatar ? UserAvatar : 'default.jpg'}`} />
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="/user/edit-password">Mudar senha</a>
                                            </li>
                                            <li>
                                                <a href="/logout">Logout / Sair</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

LayoutHeader.propTypes = {
    show: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
}
