import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
    Row,
    Col,
    Container,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import ContentLoader from "react-content-loader";
import LayoutHeader from "../../../components/header";
import LayoutSideBar from "../../../components/sidebar";
import LayoutBannerHeader from "../../../components/bannerHeader";
import { Link } from "react-router-dom";

const AllPoints = (props) => {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
    const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1).toString());

    const loadData = (month, year) => {
        setLoading(true);
        setRows([]);
        api.get(`/api/points/active?month=${month}&year=${year}`)
            .then(response => {
                setRows(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error loading data:', error);
            });
    };

    useEffect(() => {
        loadData(selectedMonth, selectedYear);
    }, [selectedMonth, selectedYear]);

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 2024; year <= currentYear; year++) {
            years.push(<option key={year} value={year}>{year}</option>);
        }
        return years;
    };

    return (
        <React.Fragment>
            <LayoutHeader isAdmin={true} show={true} />

            <Container>
                <Row>
                    <LayoutBannerHeader show={true} />

                    <Col md={9}>
                        <Row>
                            <Col xs={12} className="content-box p-0 mb-0 pb-0">
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <div className="titulo-hr mb-0 pb-0">
                                            <span>Ranking de Pontuação</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                            <Col xs={10} className="content-box" style={{ paddingBottom: '0px' }}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="sidebar-agenda" style={{ paddingBottom: '0px' }}>
                                            <Row className="sidebar-box" style={{ paddingBottom: '0px' }}>
                                                <Col md={6} className="sidebar-box">
                                                    <FormGroup floating>
                                                        <Input
                                                            type="select"
                                                            value={selectedMonth}
                                                            onChange={(event) => setSelectedMonth(event.target.value)}
                                                        >
                                                            {Array.from({ length: 12 }, (_, i) => (
                                                                <option key={i + 1} value={i + 1}>{new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(new Date(2000, i, 1))}</option>
                                                            ))}
                                                        </Input>
                                                        <Label>Mês</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} className="sidebar-box">
                                                    <FormGroup floating>
                                                        <Input
                                                            type="select"
                                                            value={selectedYear}
                                                            onChange={(event) => setSelectedYear(event.target.value)}
                                                        >
                                                            {generateYearOptions()}
                                                        </Input>
                                                        <Label>Ano</Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="sidebar-agenda" style={{ paddingTop: '0px' }}>
                                            <Row className="sidebar-box" style={{ paddingTop: '0px' }}>
                                                {!loading ?
                                                    rows.map((row, idx) => (
                                                        <Col md={12} key={idx}>
                                                            <div className="boxes">
                                                                <Row>
                                                                    <Col md={12} className="sidebar-box">
                                                                        <Row>
                                                                            <Col xs={3}>
                                                                                <div className="agenda-data-box">
                                                                                    <div className="agenda-data-position">{idx + 1}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={7}>
                                                                                <Row>
                                                                                    <Col md={12} className="bold">
                                                                                        {row.totalPoints} ponto(s)
                                                                                    </Col>
                                                                                    <Col md={12} className="uppercase bold mb-1">
                                                                                        {row.nickname || '-'}
                                                                                    </Col>
                                                                                    <Col md={12} className="mb-3">
                                                                                        <h4>{row.units ? row.units.name : '-'}</h4>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col xs={2}>
                                                                                <Link to={`/m/${row.slug}`} target="_blank">
                                                                                    <div style={{
                                                                                        position: 'relative',
                                                                                        margin: '0 auto',
                                                                                        height: '100px',
                                                                                        width: '100px',
                                                                                        backgroundImage: `url('https://api.empresariossa.com/storage/images/avatar/${row.avatar}')`,
                                                                                        backgroundSize: 'cover',
                                                                                        borderRadius: '50%'
                                                                                    }}>
                                                                                        <img alt="" className="sidebar-avatar" src="https://api.empresariossa.com/storage/images/avatar/spc.png" />
                                                                                    </div>
                                                                                </Link>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    ))
                                                    : (
                                                        <Col md={12}>
                                                            <div className="boxes">
                                                                <Row>
                                                                    <Col md={12} className="mb-3">
                                                                        <Row>
                                                                            <Col xs={1}>
                                                                                <ContentLoader
                                                                                    speed={2}
                                                                                    width="100%"
                                                                                    height="40px"
                                                                                    backgroundColor="#f3f3f3"
                                                                                    foregroundColor="#ecebeb"
                                                                                >
                                                                                    <circle cx="20" cy="20" r="20" />
                                                                                </ContentLoader>
                                                                            </Col>
                                                                            <Col xs={11}>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <ContentLoader
                                                                                            speed={2}
                                                                                            width="100%"
                                                                                            height="20px"
                                                                                            backgroundColor="#f3f3f3"
                                                                                            foregroundColor="#ecebeb"
                                                                                        >
                                                                                            <rect x="0" y="0" width="100%" height="20" />
                                                                                        </ContentLoader>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <ContentLoader
                                                                                            speed={2}
                                                                                            width="100%"
                                                                                            height="20px"
                                                                                            backgroundColor="#f3f3f3"
                                                                                            foregroundColor="#ecebeb"
                                                                                        >
                                                                                            <rect x="0" y="5" width="30%" height="10" />
                                                                                        </ContentLoader>
                                                                                    </Col>
                                                                                    <Col md={12} className="mt-3 mb-0 text-bold">
                                                                                        <ContentLoader
                                                                                            speed={2}
                                                                                            width="100%"
                                                                                            height="20px"
                                                                                            backgroundColor="#f3f3f3"
                                                                                            foregroundColor="#ecebeb"
                                                                                        >
                                                                                            <rect x="0" y="0" width="80%" height="20" />
                                                                                        </ContentLoader>
                                                                                    </Col>
                                                                                    <Col md={12} className="mt-1 mb-0">
                                                                                        <ContentLoader
                                                                                            speed={2}
                                                                                            width="100%"
                                                                                            height="120px"
                                                                                            backgroundColor="#f3f3f3"
                                                                                            foregroundColor="#ecebeb"
                                                                                        >
                                                                                            <rect x="0" y="20" width="60%" height="15" />
                                                                                            <rect x="0" y="45" width="80%" height="15" />
                                                                                            <rect x="0" y="70" width="90%" height="15" />
                                                                                            <rect x="0" y="95" width="80%" height="15" />
                                                                                        </ContentLoader>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={12} className="img-destaque">
                                                                        <ContentLoader
                                                                            speed={2}
                                                                            width="100%"
                                                                            height="400px"
                                                                            backgroundColor="#f3f3f3"
                                                                            foregroundColor="#ecebeb"
                                                                        >
                                                                            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                                                                        </ContentLoader>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </Col>

                    <Col md={3}>
                        <LayoutSideBar isAdmin={true} show={true} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AllPoints;