import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import api from "../../../services/api";

import { showToastArray, showToastErrorDefault } from '../../../helpers/generals';

const Login = props => {

  const navigate = useNavigate();

  document.title = "Login | Empresários SA";

  const [Send, setSend] = useState(false);
  const [FormData, setFormData] = useState({});

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: FormData.email || '',
      password: FormData.password || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor digite o seu e-mail"),
      password: Yup.string().required("Por favor, digite sua senha")
    }),
    onSubmit: (values) => {
      setSend(true);
      api.post('/api/login', values).then(response => {
        return response.data;
      }).then(data => {
        if (data.success == true) {

          localStorage.setItem("Token", data.access_token);
          localStorage.setItem("UserAvatar", data.user.avatar);
          localStorage.setItem("UserLogo", data.user.logo);
          localStorage.setItem("Email", data.user.email);
          localStorage.setItem("UserName", data.user.name);
          localStorage.setItem("UserID", data.user.id);
          localStorage.setItem("UserType", data.user.type);
          localStorage.setItem("authUser", JSON.stringify(data.user)); 

          setSend(false);
          navigate("/dashboard");
        }
      }).catch(error => {
        if (error) {
          let message = 'Erro ao enviar o registro!';
          setSend(false);
          if (error.response) {
            if (error.response.data) message = error.response.data.message;
          }
          showToastErrorDefault(message);
        }
      });
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <Col xs={12} className="mb-4 mt-5 text-center">
                      <img src={Logo} alt="" height="40px" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">E-mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          type="email"
                          onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                          value={FormData.email ? FormData.email : ''}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Senha</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Digite sua senha"
                          onChange={(event) => { setFormData({ ...FormData, 'password': event.target.value }); }}
                          value={FormData.password ? FormData.password : ''}
                          maxLength={15}
                          minLength={6}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-0 text-center">
                        {!Send ? (
                          <button
                            className="btn btn-primary btn-block w-100"
                            type="submit"
                          >
                            Entrar
                          </button>
                        ) : (
                          <Spinner color="info" className="mt-1 mb-1"></Spinner>
                        )}
                      </div>
                      <Row className="mt-4 text-center">
                        <Col sm="12">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Esqueci minha senha?
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <Card className="overflow-hidden mt-3">
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Link to="/new-member" className="text-muted btn btn-warning w-100 bold">
                        <i className="mdi mdi-lock me-1" />
                        Novo membro
                      </Link>
                    </Col>
                    <Col sm="6">
                      <Link to="/new-guest" className="text-muted btn btn-info w-100">
                        <i className="mdi mdi-lock me-1" />
                        Novo convidado
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;

// Login.propTypes = {
//   history: PropTypes.object,
// };
